import React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Post from "../views/layouts/Post"

const NewsPostTemplate = props => {
  const description = JSON.parse(
    get(props, "data.productPostJson.description.description", "")
  )
  const productName = get(props, "data.productPostJson.productName.productName")
  const createdAt = get(props, "data.productPostJson.createdAt")
  const url = get(props, "data.productPostJson.thumbnail.fluid", "")
  const options = {
    renderText: text => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    },
  }

  return (
    <Post location={productName}>
      <h3 className="mb-3">{productName}</h3>
      <p className="time">
        <i className="icon-time"></i> {createdAt}
      </p>
      <Img className="img-responsive" fluid={url} />
      <div className="hr_20" />
      <div>{documentToReactComponents(description, options)}</div>
    </Post>
  )
}

export default NewsPostTemplate

export const pageQuery = graphql`
  query ProductPostBySlug($slug: String!) {
    productPostJson: contentfulProduct(slug: { eq: $slug }) {
      productName {
        productName
      }
      slug
      createdAt(formatString: "MMMM Do, YYYY")
      thumbnail {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      description {
        description
      }
    }
  }
`